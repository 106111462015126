<script setup>
    import {defineProps, onMounted, ref, watch} from "vue";
    import { useStore } from 'vuex';
    
import axios from 'axios';

    const store = useStore();
    
    const props = defineProps({
        id: {
            type: [Number, String],
            required: true
        },
        category: {
            type: String,
            default: 'default'
        },
        allowUpload: {
            type: Boolean,
            default: false
        }
    });

    const isDeviceLoading = ref(true);
    const isCategoryLoading = ref(true);
    const isDeviceLoaded = ref(false);
    const isCategoryLoaded = ref(false);
    const isUploading = ref(false);

    const carImageData = ref(null);
    const catImageData = ref(null); 
    
    // Emitir evento cuando la imagen termine de cargar
    const emitLoadedEvent = () => {
        // Notificar que la imagen del dispositivo ha sido cargada
        if (window.dispatchEvent) {
            window.dispatchEvent(new CustomEvent('device-image-loaded', { 
                detail: { id: props.id } 
            }));
        }
    };

    const getImage64 = (image) => {
        return new Promise((resolve) => {
            const _tmp = document.createElement("img");
            _tmp.onload = () => {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                canvas.width = _tmp.width;
                canvas.height = _tmp.height;

                context.drawImage(_tmp, 0, 0);
                resolve(canvas.toDataURL("image/png"))
            };

            _tmp.onerror = () => {
                resolve(false);
            };

            _tmp.src = image;
        })
    }

    // Função para debounce - limita a frequência de execução de funções
    const debounce = (fn, delay) => {
        let timer;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(this, args);
            }, delay);
        };
    };
    
    // Mapa para rastrear requests em andamento
    const pendingRequests = new Map();

    const uncache = ref(0);
        
    const changeImage = ()=>{

        if(!props.allowUpload){
            return false;
        }

            const file = document.createElement("input");
            file.type = 'file';
            file.click();
            file.onchange = ()=>{

                isUploading.value = true;

                var formData = new FormData();
                formData.append("deviceId", props.id);
                formData.append("image", file.files[0]);
                axios.post('/tarkan/devices/' + props.id + '/photo', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(() => {

                    uncache.value = new Date().getTime();
                    isUploading.value = false;                  
                   
                    store.dispatch('devices/updateAttributes', {
                        id: props.deviceId,
                        attributes: {
                            hasImage: true
                        }
                    });


                    const deviceRequestKey = `device-${props.id}`;                  
                    
                    const devicePromise = getImage64(`/tarkan/assets/images/${props.id}.png?uncache=${uncache.value}`);
                    pendingRequests.set(deviceRequestKey, devicePromise);
                    
                    devicePromise
                        .then((d) => {
                            if (d) {
                                isDeviceLoaded.value = true;
                                carImageData.value = d;
                                try {
                                    store.dispatch('imageCache/cacheDeviceImage', { id: props.id, imageData: d });
                                } catch (err) {
                                    console.warn('Erro ao cachear imagem de dispositivo:', err);
                                }
                            }
                            isDeviceLoading.value = false;
                            pendingRequests.delete(deviceRequestKey);
                        })
                        .catch(() => {
                            isDeviceLoading.value = false;
                            pendingRequests.delete(deviceRequestKey);
                        });                    

                });

            }
    }
    
    const loadImages = debounce(() => {
        try {
            const deviceId = props.id || 'default';
            const deviceCategory = props.category || 'default';
            
            // Verificar se já temos a imagem em cache
            let cachedDeviceImage = null;
            let cachedCategoryImage = null;
            
            try {
                cachedDeviceImage = store.getters['imageCache/getDeviceImage'](deviceId);
                cachedCategoryImage = store.getters['imageCache/getCategoryImage'](deviceCategory);
            } catch (err) {
                console.warn('Erro ao acessar cache de imagens:', err);
            }
            
            // Definir flags de carregamento somente no início do processo
            if (!isDeviceLoaded.value && !isCategoryLoaded.value) {
                isDeviceLoading.value = true;
                isCategoryLoading.value = true;
            }
            
            let devicePromise, categoryPromise;
            
            // Verificar se o dispositivo tem imagem (com base no atributo hasImage)
            const device = store.getters['devices/getDevice'](deviceId);
            const hasImage = device && device.attributes && device.attributes.hasImage === true;
            
            if (cachedDeviceImage) {
                // Usar imagem em cache
                isDeviceLoaded.value = true;
                carImageData.value = cachedDeviceImage;
                devicePromise = Promise.resolve(cachedDeviceImage);
                // Se a imagem do dispositivo já está carregada, marcar loading como falso
                isDeviceLoading.value = false;
            } else if (hasImage) {
                // Apenas carrega a imagem se o dispositivo tiver o atributo hasImage=true
                // Verificar se já existe um request pendente para este ID
                const deviceRequestKey = `device-${deviceId}`;
                if (pendingRequests.has(deviceRequestKey)) {
                    devicePromise = pendingRequests.get(deviceRequestKey);
                } else {
                    // Carregar e cachear a imagem
                    devicePromise = getImage64(`/tarkan/assets/images/${deviceId}.png`);
                    pendingRequests.set(deviceRequestKey, devicePromise);
                    
                    devicePromise
                        .then((d) => {
                            if (d) {
                                isDeviceLoaded.value = true;
                                carImageData.value = d;
                                try {
                                    store.dispatch('imageCache/cacheDeviceImage', { id: deviceId, imageData: d });
                                } catch (err) {
                                    console.warn('Erro ao cachear imagem de dispositivo:', err);
                                }
                            }
                            isDeviceLoading.value = false;
                            pendingRequests.delete(deviceRequestKey);
                        })
                        .catch(() => {
                            isDeviceLoading.value = false;
                            pendingRequests.delete(deviceRequestKey);
                        });
                }
            } else {
                // Se não tem imagem, não faz a requisição e finaliza o carregamento
                isDeviceLoading.value = false;
                devicePromise = Promise.resolve(null);
            }
            
            if (cachedCategoryImage) {
                // Usar imagem em cache
                isCategoryLoaded.value = true;
                catImageData.value = cachedCategoryImage;
                categoryPromise = Promise.resolve(cachedCategoryImage);
                // Se a imagem da categoria já está carregada, marcar loading como falso
                isCategoryLoading.value = false;
            } else {
                // Verificar se já existe um request pendente para esta categoria
                const categoryRequestKey = `category-${deviceCategory}`;
                if (pendingRequests.has(categoryRequestKey)) {
                    categoryPromise = pendingRequests.get(categoryRequestKey);
                } else {
                    // Carregar e cachear a imagem
                    categoryPromise = getImage64(`/tarkan/assets/images/categories/${deviceCategory}.png`);
                    pendingRequests.set(categoryRequestKey, categoryPromise);
                    
                    categoryPromise
                        .then((d) => {
                            if (d) {
                                isCategoryLoaded.value = true;
                                catImageData.value = d;
                                try {
                                    store.dispatch('imageCache/cacheCategoryImage', { category: deviceCategory, imageData: d });
                                } catch (err) {
                                    console.warn('Erro ao cachear imagem de categoria:', err);
                                }
                            }
                            isCategoryLoading.value = false;
                            pendingRequests.delete(categoryRequestKey);
                        })
                        .catch(() => {
                            isCategoryLoading.value = false;
                            pendingRequests.delete(categoryRequestKey);
                        });
                }
            }

            // Usar um timer de fallback para garantir que o loading não fique preso
            const fallbackTimer = setTimeout(() => {
                if (isDeviceLoading.value) isDeviceLoading.value = false;
                if (isCategoryLoading.value) isCategoryLoading.value = false;
                
                // Emitir evento mesmo se houver timeout
                emitLoadedEvent();
            }, 5000); // 5 segundos de timeout

            // Promise.all só é disparado se ambas as promises estiverem concluídas com sucesso
            Promise.all([
                devicePromise || Promise.resolve(),
                categoryPromise || Promise.resolve()
            ])
            .then(() => {
                clearTimeout(fallbackTimer);
                isDeviceLoading.value = false;
                isCategoryLoading.value = false;
                // Emitir evento quando termina de carregar
                emitLoadedEvent();
            })
            .catch((err) => {
                clearTimeout(fallbackTimer);
                console.error('Erro ao carregar imagens:', err);
                isDeviceLoading.value = false;
                isCategoryLoading.value = false;
                emitLoadedEvent();
            });
        } catch (err) {
            console.error('Erro durante o carregamento de imagens:', err);
            isDeviceLoading.value = false;
            isCategoryLoading.value = false;
            // Emitir evento mesmo em caso de erro
            emitLoadedEvent();
        }
    }, 300); // Adiciona um debounce de 300ms

    // Vigilar cambios en el ID o categoría para recargar la imagen
    watch(() => props.id, () => {
        loadImages();
    });

    watch(() => props.category, () => {
        loadImages();
    });

    // Cuando se añade un nuevo dispositivo, recargar todas las imágenes
    const handleDeviceAdded = () => {
        loadImages();
    };

    onMounted(() => {
        loadImages();
        
        // Escuchar evento de nuevo dispositivo añadido
        window.addEventListener('device-added', handleDeviceAdded);
    });
</script>

<template>
    <div class="device-img-container" style="position: relative;">
        <div v-if="isDeviceLoading && isCategoryLoading || isUploading" class="loading-indicator">
            <img src="/img/pulse2.gif" alt="Cargando...">
        </div>
        <div v-else-if="isDeviceLoaded" class="device-image loaded">
            <img :src="carImageData" alt="Vehículo">
        </div>
        <div v-else-if="isCategoryLoaded" class="device-image category">
            <img :src="catImageData" alt="Categoría">
        </div>
        <div v-else class="device-image error">
            <i class="fas fa-car"></i>
        </div>

        <div v-if="allowUpload" @click="changeImage()" class="image-uploader-action">
            <div>
                    Upload Image
            </div>
        </div>
    </div>
</template>

<style scoped>

.image-uploader-action{
    opacity: 0;
    background: rgba(0,0,0,0.5);
    width: calc(100% - 30px);
    height: calc(100% - 30px);
    position: absolute;
     left: 15px; 
     top: 15px;
     display: flex; 
     align-items: center;
     justify-content: center;
     transition: all 0.3s ease-in-out;
     color: white;
     border-radius: 15px;
}

.image-uploader-action:hover{
    opacity: 1;
}

.device-img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.device-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    transition: opacity 0.3s ease;
    border-radius: 15px;
}

.loading-indicator {
    opacity: 0.7;
}

.loaded img {
    opacity: 1;
}

.category img {
    opacity: 0.9;
}

.error {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999;
    font-size: 24px;
}
</style>